/* login.css */

.login_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login_container {
    border: 2px solid blue;
    border-radius: 20px;
    width: 30%;
    height: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  

/* ::placeholder{
    color: black;
}   */
.user_name{
    width: 100%;
    
}

.userDetails{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border: 1px;
    gap:50%
}
.user_input{
    width: 75%;
    border: 2px solid rgb(183, 183, 235);
    border-radius: 5px;
    background-color: rgb(140, 60, 214);
    color: white;
    /* text-align: center; */
}
.user_input:focus{
    border-color: #007bff; /* Border color when input is in focus */
  /* outline: none; */
}
.submit{
    width: 30%;
    height: 8%;
    background-color:rgb(67, 52, 233);
    color: white;
    border: none;
    border-radius: 5px;

}
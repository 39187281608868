.header_section{
    display: flex;
    flex-direction: row;
    background-color: #e0e0e0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50%;
    position: relative;
}
.header{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    gap: 0.3rem;
}
.button_{
    padding: 30%;
}
.header_image{
    width:1.875rem;
    height:1.125rem;
}
.assis{
    /* position: abso; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
    justify-content: space-between;
    margin-top: 1%;
    width: 100%;
    /* padding: 0.64%; */
    /* background: #F1F1F1; */
    border-bottom: 1px solid #3333333d;
}
.chat_subheader{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: fit-content;
    color: rgba(0, 0, 0, 0.8);
}
#closeChat{
    cursor: pointer;
    display: flex;
    flex-direction: row;    
    align-items: center;
    padding: 1%;
    gap: 10%;
}
.close_chat{
    padding: 0.2rem;
}
select{
    font-size: 0.75rem;
    padding: 4px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    width: 45%;
    text-align: center;
}
select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
  
  /* Style the options inside the select */
select option {
    background-color: white;
    color: #333;
}
  
  /* Style the select when an option is selected */
select option:checked {
    background-color: #007bff;
    color: white;
}
.button_{
    background: none;
    border: none;
    cursor: pointer;
}
.maximize{
    width: 100%;
    height: 350px;
    background-color: white;
    display: flex;
    flex-direction: column; 
}
.chat_subheader_section{
    display: flex;
    /* justify-content: space-between; */
    min-width: 150%;
    gap: 2%;
    align-items: center;
    padding-left: 2%;
}
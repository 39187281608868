.chatbox_footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: linear-gradient(93.12deg, #581B98 0.52%, #9C1DE7 100%);
    bottom: 0;
    gap: 1%;
    color: white;
    width: 100%;
    position: relative;
}
.chatbox_file{
    /* flex: 1; */
    display: flex;
    justify-content: center;
    padding-right: 0.1rem;
    padding-left: 0.3rem;
}
.chatbox_input{
    display: flex;
    flex: 8;
    width: 100%;
    padding-top: 0.3rem;
    padding-bottom: 0.1rem;
}
.prompt_tokens{
    position: absolute;
    bottom: 10%;
    right: 1%;
    font-size: smaller;
    color: #581B98;
    /* background-color: #581B98; */
    /* border-radius: px; */
    opacity: 2;
    z-index: 1;
}
.status{
    display: flex;
    flex-direction: column;
}
.status_check{
    font-size: medium;
    text-align: center;
}
input{
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0.3rem;
}
textarea{
  outline: none;
    resize: none;
    width: 100%;
    max-height: 100%;
    padding: 0.2rem;
    /* font-size: medium; */
    /* overflow-y: scroll; */
}
textarea::-webkit-scrollbar {
	width: 4px;
	background-color: #F5F5F5;
    cursor: pointer;
}

textarea::-webkit-scrollbar-thumb {
    border-radius: 5px;
	background-color: #8054ae;
    cursor: pointer;
}
.send__button {
    /* ; */
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
::placeholder{
    color: white;
}
input:focus {
    outline: none; 
    border: none;
  }

  .drop-up {
    position: absolute;
    /* top: -155%;  */
    bottom: 3rem;
    left: 0;
    width: 100%;
    
    /* border: 1px solid #ccc; */
    max-height: 60px;
    overflow-y: auto;
    z-index: 1000;
    font-size: small;
  }
  
  .suggestion-item {
    overflow: auto;
    padding: 8px;
    cursor: pointer;
    &:hover {
        background-color:rgb(142, 231, 142);
        color: white;
    }
  }
   
   .drop-up{
    color:white;
    background-color: #9C1DE7;
   }
   .file_download{
    padding-right: 0.1rem;
   }
   
  
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}
.main-app{
  width: 100vw;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
}

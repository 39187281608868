.main_chatbot{
    height: 69.44%; /* 500px*/
    width: 40%;/* 320px */
    position: absolute;
    bottom: 5%;
    right: 5%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .logout_bt{
    display: flex;
    position: absolute;
    right: 5%;
    top: 5%;
    gap: 1%;
    font-size: smaller;
    border-radius: 20%;
    /* border: 20px solid blue; */
  }
  
  .chatbox__button{
    height: 10%; /* 500px*/
    width: 10%;
    position: absolute;
    bottom: 5%;
    right: 1%;
    border: none;
  }
  .chatbox__button button {
    cursor: pointer;
}
  .max_chats{
    height: 90%; /* 500px*/
    width: 90%;/* 320px */
    position: absolute;
    bottom: 5%;
    right: 5%;
    /* border: 2px solid blue; */ 
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
.token_info{
  /* display: flex;
  flex-direction: column; */
  width: 20%;
  position: absolute;
  right: 9%;
  top:4%;
}
.t_info{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: smaller;
  
  margin-bottom: 10%;
}


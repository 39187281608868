.thread_title{
    cursor: pointer;
    font-size: small;
}
.session{
    padding: 2% 0.625% 3% 5%;
    margin: 10px;
    background-color: #581B98;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: space-between;
}
.delete_session{
    cursor: pointer;
}
